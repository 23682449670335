import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { Variables } from 'graphql-request';
import { Storage } from '@ionic/storage';
import { useEffect, useState } from 'react';
import { UserState } from './authUtils';
import getGraphQLClient from './graphQLClientUtil';
import GET_REGIONS_LIST, { RegionsType } from '../graphql/GetRegionsList';
import { useAuth } from './authContext';

export const regionKeyString = 'associatedRegions';

const useRegionList = (userState: UserState, storage: Storage) => {
  const auth = useAuth();
  const user = auth?.user;
  const [regionsCached, setRegionsCached] = useState<any>(null);
  
  useEffect(() => {
    const loadStoredBase = async () => {
      const stored = await storage.get(regionKeyString) || [];      
      setRegionsCached(stored);
    };
    loadStoredBase();
  }, [storage]);
  const graphQLClient = getGraphQLClient(userState);
  const variablesRegion: Variables = {
    userGuid: user?.userGuid,
  };
  const loadRegionEnabled = regionsCached !== null && regionsCached.length === 0;

  const regionQuery = useQuery({
    queryKey: [...[regionKeyString], variablesRegion],
    queryFn: (ctx: QueryFunctionContext) => graphQLClient.request(GET_REGIONS_LIST, ctx.queryKey[1]),
    enabled: loadRegionEnabled,
    refetchOnWindowFocus: false,
  });

  const { data: dataRegions, error: errorRegions, isLoading: isLoadingRegions, isFetching: isFetchingRegions } = regionQuery;

  let regions: RegionsType[] = [];
  if (regionsCached?.length > 0) {
    regions = regionsCached;
  } else {
    regions = !isLoadingRegions && !isFetchingRegions ? dataRegions?.[regionKeyString] || [] : [];
  }
  if (regions.length > 0 && regionsCached?.length === 0) {
    storage.set(regionKeyString, regions);
  }
  // eslint-disable-next-line no-console
  if (errorRegions) console.error(errorRegions);
  // default region when API doesn't return any region
  if (regions?.length === 0) {
    const defaultRegion: RegionsType = {
      regionId: 1,
      regionCode: '',
      regionCodeShort: '',
      regionName: 'Ontario',
    };
    regions.push(defaultRegion);
  }
  return regions;
};

export default useRegionList;